var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import RcTooltip from 'rc-tooltip';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import * as React from 'react';
import { getTransitionName } from '../_util/motion';
import getPlacements from '../_util/placements';
import { cloneElement, isFragment, isValidElement } from '../_util/reactNode';
import warning from '../_util/warning';
import { ConfigContext } from '../config-provider';
import { NoCompactStyle } from '../space/Compact';
import theme from '../theme';
import PurePanel from './PurePanel';
import useStyle from './style';
import { parseColor } from './util';
const { useToken } = theme;
const splitObject = (obj, keys) => {
    const picked = {};
    const omitted = Object.assign({}, obj);
    keys.forEach((key) => {
        if (obj && key in obj) {
            picked[key] = obj[key];
            delete omitted[key];
        }
    });
    return { picked, omitted };
};
// Fix Tooltip won't hide at disabled button
// mouse events don't trigger at disabled button in Chrome
// https://github.com/react-component/tooltip/issues/18
function getDisabledCompatibleChildren(element, prefixCls) {
    const elementType = element.type;
    if (((elementType.__ANT_BUTTON === true || element.type === 'button') && element.props.disabled) ||
        (elementType.__ANT_SWITCH === true && (element.props.disabled || element.props.loading)) ||
        (elementType.__ANT_RADIO === true && element.props.disabled)) {
        // Pick some layout related style properties up to span
        // Prevent layout bugs like https://github.com/ant-design/ant-design/issues/5254
        const { picked, omitted } = splitObject(element.props.style, [
            'position',
            'left',
            'right',
            'top',
            'bottom',
            'float',
            'display',
            'zIndex',
        ]);
        const spanStyle = Object.assign(Object.assign({ display: 'inline-block' }, picked), { cursor: 'not-allowed', width: element.props.block ? '100%' : undefined });
        const buttonStyle = Object.assign(Object.assign({}, omitted), { pointerEvents: 'none' });
        const child = cloneElement(element, {
            style: buttonStyle,
            className: null,
        });
        return (React.createElement("span", { style: spanStyle, className: classNames(element.props.className, `${prefixCls}-disabled-compatible-wrapper`) }, child));
    }
    return element;
}
const Tooltip = React.forwardRef((props, ref) => {
    var _a, _b;
    const { prefixCls: customizePrefixCls, openClassName, getTooltipContainer, overlayClassName, color, overlayInnerStyle, children, afterOpenChange, afterVisibleChange, destroyTooltipOnHide, arrow = true, title, overlay, builtinPlacements, arrowPointAtCenter = false, autoAdjustOverflow = true, } = props;
    const mergedShowArrow = !!arrow;
    const { token } = useToken();
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, } = React.useContext(ConfigContext);
    // ============================== Ref ===============================
    const tooltipRef = React.useRef(null);
    const forceAlign = () => {
        var _a;
        (_a = tooltipRef.current) === null || _a === void 0 ? void 0 : _a.forceAlign();
    };
    React.useImperativeHandle(ref, () => ({
        forceAlign,
        forcePopupAlign: () => {
            warning(false, 'Tooltip', '`forcePopupAlign` is align to `forceAlign` instead.');
            forceAlign();
        },
    }));
    // ============================== Warn ==============================
    if (process.env.NODE_ENV !== 'production') {
        [
            ['visible', 'open'],
            ['defaultVisible', 'defaultOpen'],
            ['onVisibleChange', 'onOpenChange'],
            ['afterVisibleChange', 'afterOpenChange'],
            ['arrowPointAtCenter', 'arrow'],
        ].forEach(([deprecatedName, newName]) => {
            warning(!(deprecatedName in props), 'Tooltip', `\`${deprecatedName}\` is deprecated, please use \`${newName}\` instead.`);
        });
        warning(!destroyTooltipOnHide || typeof destroyTooltipOnHide === 'boolean', 'Tooltip', '`destroyTooltipOnHide` no need config `keepParent` anymore. Please use `boolean` value directly.');
        warning(!arrow || typeof arrow === 'boolean' || !('arrowPointAtCenter' in arrow), 'Tooltip', '`arrowPointAtCenter` in `arrow` is deprecated, please use `pointAtCenter` instead.');
    }
    // ============================== Open ==============================
    const [open, setOpen] = useMergedState(false, {
        value: (_a = props.open) !== null && _a !== void 0 ? _a : props.visible,
        defaultValue: (_b = props.defaultOpen) !== null && _b !== void 0 ? _b : props.defaultVisible,
    });
    const noTitle = !title && !overlay && title !== 0; // overlay for old version compatibility
    const onOpenChange = (vis) => {
        var _a, _b;
        setOpen(noTitle ? false : vis);
        if (!noTitle) {
            (_a = props.onOpenChange) === null || _a === void 0 ? void 0 : _a.call(props, vis);
            (_b = props.onVisibleChange) === null || _b === void 0 ? void 0 : _b.call(props, vis);
        }
    };
    const tooltipPlacements = React.useMemo(() => {
        var _a, _b;
        let mergedArrowPointAtCenter = arrowPointAtCenter;
        if (typeof arrow === 'object') {
            mergedArrowPointAtCenter =
                (_b = (_a = arrow.pointAtCenter) !== null && _a !== void 0 ? _a : arrow.arrowPointAtCenter) !== null && _b !== void 0 ? _b : arrowPointAtCenter;
        }
        return (builtinPlacements ||
            getPlacements({
                arrowPointAtCenter: mergedArrowPointAtCenter,
                autoAdjustOverflow,
                arrowWidth: mergedShowArrow ? token.sizePopupArrow : 0,
                borderRadius: token.borderRadius,
                offset: token.marginXXS,
            }));
    }, [arrowPointAtCenter, arrow, builtinPlacements, token]);
    const memoOverlay = React.useMemo(() => {
        if (title === 0) {
            return title;
        }
        return overlay || title || '';
    }, [overlay, title]);
    const memoOverlayWrapper = (React.createElement(NoCompactStyle, null, typeof memoOverlay === 'function' ? memoOverlay() : memoOverlay));
    const { getPopupContainer, placement = 'top', mouseEnterDelay = 0.1, mouseLeaveDelay = 0.1, overlayStyle, rootClassName } = props, otherProps = __rest(props, ["getPopupContainer", "placement", "mouseEnterDelay", "mouseLeaveDelay", "overlayStyle", "rootClassName"]);
    const prefixCls = getPrefixCls('tooltip', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const injectFromPopover = props['data-popover-inject'];
    let tempOpen = open;
    // Hide tooltip when there is no title
    if (!('open' in props) && !('visible' in props) && noTitle) {
        tempOpen = false;
    }
    // ============================= Render =============================
    const child = getDisabledCompatibleChildren(isValidElement(children) && !isFragment(children) ? children : React.createElement("span", null, children), prefixCls);
    const childProps = child.props;
    const childCls = !childProps.className || typeof childProps.className === 'string'
        ? classNames(childProps.className, {
            [openClassName || `${prefixCls}-open`]: true,
        })
        : childProps.className;
    // Style
    const [wrapSSR, hashId] = useStyle(prefixCls, !injectFromPopover);
    // Color
    const colorInfo = parseColor(prefixCls, color);
    const formattedOverlayInnerStyle = Object.assign(Object.assign({}, overlayInnerStyle), colorInfo.overlayStyle);
    const arrowContentStyle = colorInfo.arrowStyle;
    const customOverlayClassName = classNames(overlayClassName, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, colorInfo.className, rootClassName, hashId);
    return wrapSSR(React.createElement(RcTooltip, Object.assign({}, otherProps, { showArrow: mergedShowArrow, placement: placement, mouseEnterDelay: mouseEnterDelay, mouseLeaveDelay: mouseLeaveDelay, prefixCls: prefixCls, overlayClassName: customOverlayClassName, overlayStyle: Object.assign(Object.assign({}, arrowContentStyle), overlayStyle), getTooltipContainer: getPopupContainer || getTooltipContainer || getContextPopupContainer, ref: tooltipRef, builtinPlacements: tooltipPlacements, overlay: memoOverlayWrapper, visible: tempOpen, onVisibleChange: onOpenChange, afterVisibleChange: afterOpenChange !== null && afterOpenChange !== void 0 ? afterOpenChange : afterVisibleChange, overlayInnerStyle: formattedOverlayInnerStyle, arrowContent: React.createElement("span", { className: `${prefixCls}-arrow-content` }), motion: {
            motionName: getTransitionName(rootPrefixCls, 'zoom-big-fast', props.transitionName),
            motionDeadline: 1000,
        }, destroyTooltipOnHide: !!destroyTooltipOnHide }), tempOpen ? cloneElement(child, { className: childCls }) : child));
});
if (process.env.NODE_ENV !== 'production') {
    Tooltip.displayName = 'Tooltip';
}
Tooltip._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
export default Tooltip;
