import { genFocusStyle } from '../../style';
import { genCompactItemStyle } from '../../style/compact-item';
import { genCompactItemVerticalStyle } from '../../style/compact-item-vertical';
import { genComponentStyleHook, mergeToken } from '../../theme/internal';
import genGroupStyle from './group';
// ============================== Shared ==============================
const genSharedButtonStyle = (token) => {
    const { componentCls, iconCls, buttonFontWeight } = token;
    return {
        [componentCls]: {
            outline: 'none',
            position: 'relative',
            display: 'inline-block',
            fontWeight: buttonFontWeight,
            whiteSpace: 'nowrap',
            textAlign: 'center',
            backgroundImage: 'none',
            backgroundColor: 'transparent',
            border: `${token.lineWidth}px ${token.lineType} transparent`,
            cursor: 'pointer',
            transition: `all ${token.motionDurationMid} ${token.motionEaseInOut}`,
            userSelect: 'none',
            touchAction: 'manipulation',
            lineHeight: token.lineHeight,
            color: token.colorText,
            '> span': {
                display: 'inline-block',
            },
            [`${componentCls}-icon`]: {
                lineHeight: 0,
            },
            // Leave a space between icon and text.
            [`&:not(${componentCls}-icon-only) > ${componentCls}-icon`]: {
                [`&${componentCls}-loading-icon, &:not(:last-child)`]: {
                    marginInlineEnd: token.marginXS,
                },
            },
            // Special case for anticon after children
            [`> span + ${iconCls}`]: {
                marginInlineStart: token.marginXS,
            },
            '> a': {
                color: 'currentColor',
            },
            '&:not(:disabled)': Object.assign({}, genFocusStyle(token)),
            // make `btn-icon-only` not too narrow
            [`&-icon-only${componentCls}-compact-item`]: {
                flex: 'none',
            },
            // Special styles for Primary Button
            [`&-compact-item${componentCls}-primary`]: {
                [`&:not([disabled]) + ${componentCls}-compact-item${componentCls}-primary:not([disabled])`]: {
                    position: 'relative',
                    '&:before': {
                        position: 'absolute',
                        top: -token.lineWidth,
                        insetInlineStart: -token.lineWidth,
                        display: 'inline-block',
                        width: token.lineWidth,
                        height: `calc(100% + ${token.lineWidth * 2}px)`,
                        backgroundColor: token.colorPrimaryHover,
                        content: '""',
                    },
                },
            },
            // Special styles for Primary Button
            '&-compact-vertical-item': {
                [`&${componentCls}-primary`]: {
                    [`&:not([disabled]) + ${componentCls}-compact-vertical-item${componentCls}-primary:not([disabled])`]: {
                        position: 'relative',
                        '&:before': {
                            position: 'absolute',
                            top: -token.lineWidth,
                            insetInlineStart: -token.lineWidth,
                            display: 'inline-block',
                            width: `calc(100% + ${token.lineWidth * 2}px)`,
                            height: token.lineWidth,
                            backgroundColor: token.colorPrimaryHover,
                            content: '""',
                        },
                    },
                },
            },
        },
    };
};
const genHoverActiveButtonStyle = (hoverStyle, activeStyle) => ({
    '&:not(:disabled)': {
        '&:hover': hoverStyle,
        '&:active': activeStyle,
    },
});
// ============================== Shape ===============================
const genCircleButtonStyle = (token) => ({
    minWidth: token.controlHeight,
    paddingInlineStart: 0,
    paddingInlineEnd: 0,
    borderRadius: '50%',
});
const genRoundButtonStyle = (token) => ({
    borderRadius: token.controlHeight,
    paddingInlineStart: token.controlHeight / 2,
    paddingInlineEnd: token.controlHeight / 2,
});
// =============================== Type ===============================
const genDisabledStyle = (token) => ({
    cursor: 'not-allowed',
    borderColor: token.colorBorder,
    color: token.colorTextDisabled,
    backgroundColor: token.colorBgContainerDisabled,
    boxShadow: 'none',
});
const genGhostButtonStyle = (btnCls, textColor, borderColor, textColorDisabled, borderColorDisabled, hoverStyle, activeStyle) => ({
    [`&${btnCls}-background-ghost`]: Object.assign(Object.assign({ color: textColor || undefined, backgroundColor: 'transparent', borderColor: borderColor || undefined, boxShadow: 'none' }, genHoverActiveButtonStyle(Object.assign({ backgroundColor: 'transparent' }, hoverStyle), Object.assign({ backgroundColor: 'transparent' }, activeStyle))), { '&:disabled': {
            cursor: 'not-allowed',
            color: textColorDisabled || undefined,
            borderColor: borderColorDisabled || undefined,
        } }),
});
const genSolidDisabledButtonStyle = (token) => ({
    '&:disabled': Object.assign({}, genDisabledStyle(token)),
});
const genSolidButtonStyle = (token) => (Object.assign({}, genSolidDisabledButtonStyle(token)));
const genPureDisabledButtonStyle = (token) => ({
    '&:disabled': {
        cursor: 'not-allowed',
        color: token.colorTextDisabled,
    },
});
// Type: Default
const genDefaultButtonStyle = (token) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, genSolidButtonStyle(token)), { backgroundColor: token.colorBgContainer, borderColor: token.colorBorder, boxShadow: `0 ${token.controlOutlineWidth}px 0 ${token.controlTmpOutline}` }), genHoverActiveButtonStyle({
    color: token.colorPrimaryHover,
    borderColor: token.colorPrimaryHover,
}, {
    color: token.colorPrimaryActive,
    borderColor: token.colorPrimaryActive,
})), genGhostButtonStyle(token.componentCls, token.colorBgContainer, token.colorBgContainer, token.colorTextDisabled, token.colorBorder)), { [`&${token.componentCls}-dangerous`]: Object.assign(Object.assign(Object.assign({ color: token.colorError, borderColor: token.colorError }, genHoverActiveButtonStyle({
        color: token.colorErrorHover,
        borderColor: token.colorErrorBorderHover,
    }, {
        color: token.colorErrorActive,
        borderColor: token.colorErrorActive,
    })), genGhostButtonStyle(token.componentCls, token.colorError, token.colorError, token.colorTextDisabled, token.colorBorder)), genSolidDisabledButtonStyle(token)) }));
// Type: Primary
const genPrimaryButtonStyle = (token) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, genSolidButtonStyle(token)), { color: token.colorTextLightSolid, backgroundColor: token.colorPrimary, boxShadow: `0 ${token.controlOutlineWidth}px 0 ${token.controlOutline}` }), genHoverActiveButtonStyle({
    color: token.colorTextLightSolid,
    backgroundColor: token.colorPrimaryHover,
}, {
    color: token.colorTextLightSolid,
    backgroundColor: token.colorPrimaryActive,
})), genGhostButtonStyle(token.componentCls, token.colorPrimary, token.colorPrimary, token.colorTextDisabled, token.colorBorder, {
    color: token.colorPrimaryHover,
    borderColor: token.colorPrimaryHover,
}, {
    color: token.colorPrimaryActive,
    borderColor: token.colorPrimaryActive,
})), { [`&${token.componentCls}-dangerous`]: Object.assign(Object.assign(Object.assign({ backgroundColor: token.colorError, boxShadow: `0 ${token.controlOutlineWidth}px 0 ${token.colorErrorOutline}` }, genHoverActiveButtonStyle({
        backgroundColor: token.colorErrorHover,
    }, {
        backgroundColor: token.colorErrorActive,
    })), genGhostButtonStyle(token.componentCls, token.colorError, token.colorError, token.colorTextDisabled, token.colorBorder, {
        color: token.colorErrorHover,
        borderColor: token.colorErrorHover,
    }, {
        color: token.colorErrorActive,
        borderColor: token.colorErrorActive,
    })), genSolidDisabledButtonStyle(token)) }));
// Type: Dashed
const genDashedButtonStyle = (token) => (Object.assign(Object.assign({}, genDefaultButtonStyle(token)), { borderStyle: 'dashed' }));
// Type: Link
const genLinkButtonStyle = (token) => (Object.assign(Object.assign(Object.assign({ color: token.colorLink }, genHoverActiveButtonStyle({
    color: token.colorLinkHover,
}, {
    color: token.colorLinkActive,
})), genPureDisabledButtonStyle(token)), { [`&${token.componentCls}-dangerous`]: Object.assign(Object.assign({ color: token.colorError }, genHoverActiveButtonStyle({
        color: token.colorErrorHover,
    }, {
        color: token.colorErrorActive,
    })), genPureDisabledButtonStyle(token)) }));
// Type: Text
const genTextButtonStyle = (token) => (Object.assign(Object.assign(Object.assign({}, genHoverActiveButtonStyle({
    color: token.colorText,
    backgroundColor: token.colorBgTextHover,
}, {
    color: token.colorText,
    backgroundColor: token.colorBgTextActive,
})), genPureDisabledButtonStyle(token)), { [`&${token.componentCls}-dangerous`]: Object.assign(Object.assign({ color: token.colorError }, genPureDisabledButtonStyle(token)), genHoverActiveButtonStyle({
        color: token.colorErrorHover,
        backgroundColor: token.colorErrorBg,
    }, {
        color: token.colorErrorHover,
        backgroundColor: token.colorErrorBg,
    })) }));
// Href and Disabled
const genDisabledButtonStyle = (token) => (Object.assign(Object.assign({}, genDisabledStyle(token)), { [`&${token.componentCls}:hover`]: Object.assign({}, genDisabledStyle(token)) }));
const genTypeButtonStyle = (token) => {
    const { componentCls } = token;
    return {
        [`${componentCls}-default`]: genDefaultButtonStyle(token),
        [`${componentCls}-primary`]: genPrimaryButtonStyle(token),
        [`${componentCls}-dashed`]: genDashedButtonStyle(token),
        [`${componentCls}-link`]: genLinkButtonStyle(token),
        [`${componentCls}-text`]: genTextButtonStyle(token),
        [`${componentCls}-disabled`]: genDisabledButtonStyle(token),
    };
};
// =============================== Size ===============================
const genSizeButtonStyle = (token, sizePrefixCls = '') => {
    const { componentCls, controlHeight, fontSize, lineHeight, lineWidth, borderRadius, buttonPaddingHorizontal, iconCls, } = token;
    const paddingVertical = Math.max(0, (controlHeight - fontSize * lineHeight) / 2 - lineWidth);
    const paddingHorizontal = buttonPaddingHorizontal - lineWidth;
    const iconOnlyCls = `${componentCls}-icon-only`;
    return [
        // Size
        {
            [`${componentCls}${sizePrefixCls}`]: {
                fontSize,
                height: controlHeight,
                padding: `${paddingVertical}px ${paddingHorizontal}px`,
                borderRadius,
                [`&${iconOnlyCls}`]: {
                    width: controlHeight,
                    paddingInlineStart: 0,
                    paddingInlineEnd: 0,
                    [`&${componentCls}-round`]: {
                        width: 'auto',
                    },
                    [iconCls]: {
                        fontSize: token.buttonIconOnlyFontSize,
                    },
                },
                // Loading
                [`&${componentCls}-loading`]: {
                    opacity: token.opacityLoading,
                    cursor: 'default',
                },
                [`${componentCls}-loading-icon`]: {
                    transition: `width ${token.motionDurationSlow} ${token.motionEaseInOut}, opacity ${token.motionDurationSlow} ${token.motionEaseInOut}`,
                },
            },
        },
        // Shape - patch prefixCls again to override solid border radius style
        {
            [`${componentCls}${componentCls}-circle${sizePrefixCls}`]: genCircleButtonStyle(token),
        },
        {
            [`${componentCls}${componentCls}-round${sizePrefixCls}`]: genRoundButtonStyle(token),
        },
    ];
};
const genSizeBaseButtonStyle = (token) => genSizeButtonStyle(token);
const genSizeSmallButtonStyle = (token) => {
    const smallToken = mergeToken(token, {
        controlHeight: token.controlHeightSM,
        padding: token.paddingXS,
        buttonPaddingHorizontal: 8,
        borderRadius: token.borderRadiusSM,
        buttonIconOnlyFontSize: token.fontSizeLG - 2,
    });
    return genSizeButtonStyle(smallToken, `${token.componentCls}-sm`);
};
const genSizeLargeButtonStyle = (token) => {
    const largeToken = mergeToken(token, {
        controlHeight: token.controlHeightLG,
        fontSize: token.fontSizeLG,
        borderRadius: token.borderRadiusLG,
        buttonIconOnlyFontSize: token.fontSizeLG + 2,
    });
    return genSizeButtonStyle(largeToken, `${token.componentCls}-lg`);
};
const genBlockButtonStyle = (token) => {
    const { componentCls } = token;
    return {
        [componentCls]: {
            [`&${componentCls}-block`]: {
                width: '100%',
            },
        },
    };
};
// ============================== Export ==============================
export default genComponentStyleHook('Button', (token) => {
    const { controlTmpOutline, paddingContentHorizontal } = token;
    const buttonToken = mergeToken(token, {
        colorOutlineDefault: controlTmpOutline,
        buttonPaddingHorizontal: paddingContentHorizontal,
        buttonIconOnlyFontSize: token.fontSizeLG,
        buttonFontWeight: 400,
    });
    return [
        // Shared
        genSharedButtonStyle(buttonToken),
        // Size
        genSizeSmallButtonStyle(buttonToken),
        genSizeBaseButtonStyle(buttonToken),
        genSizeLargeButtonStyle(buttonToken),
        // Block
        genBlockButtonStyle(buttonToken),
        // Group (type, ghost, danger, disabled, loading)
        genTypeButtonStyle(buttonToken),
        // Button Group
        genGroupStyle(buttonToken),
        // Space Compact
        genCompactItemStyle(token),
        genCompactItemVerticalStyle(token),
    ];
});
