import { genFocusOutline } from '../../style';
const accessibilityFocus = (token) => (Object.assign({}, genFocusOutline(token)));
const getThemeStyle = (token, themeSuffix) => {
    const { componentCls, colorItemText, colorItemTextSelected, colorGroupTitle, colorItemBg, colorSubItemBg, colorItemBgSelected, colorActiveBarHeight, colorActiveBarWidth, colorActiveBarBorderSize, motionDurationSlow, motionEaseInOut, motionEaseOut, menuItemPaddingInline, motionDurationMid, colorItemTextHover, lineType, colorSplit, 
    // Disabled
    colorItemTextDisabled, 
    // Danger
    colorDangerItemText, colorDangerItemTextHover, colorDangerItemTextSelected, colorDangerItemBgActive, colorDangerItemBgSelected, colorItemBgHover, menuSubMenuBg, 
    // Horizontal
    colorItemTextSelectedHorizontal, colorItemBgSelectedHorizontal, } = token;
    return {
        [`${componentCls}-${themeSuffix}, ${componentCls}-${themeSuffix} > ${componentCls}`]: {
            color: colorItemText,
            background: colorItemBg,
            [`&${componentCls}-root:focus-visible`]: Object.assign({}, accessibilityFocus(token)),
            // ======================== Item ========================
            [`${componentCls}-item-group-title`]: {
                color: colorGroupTitle,
            },
            [`${componentCls}-submenu-selected`]: {
                [`> ${componentCls}-submenu-title`]: {
                    color: colorItemTextSelected,
                },
            },
            // Disabled
            [`${componentCls}-item-disabled, ${componentCls}-submenu-disabled`]: {
                color: `${colorItemTextDisabled} !important`,
            },
            // Hover
            [`${componentCls}-item:hover, ${componentCls}-submenu-title:hover`]: {
                [`&:not(${componentCls}-item-selected):not(${componentCls}-submenu-selected)`]: {
                    color: colorItemTextHover,
                },
            },
            [`&:not(${componentCls}-horizontal)`]: {
                [`${componentCls}-item:not(${componentCls}-item-selected)`]: {
                    '&:hover': {
                        backgroundColor: colorItemBgHover,
                    },
                    '&:active': {
                        backgroundColor: colorItemBgSelected,
                    },
                },
                [`${componentCls}-submenu-title`]: {
                    '&:hover': {
                        backgroundColor: colorItemBgHover,
                    },
                    '&:active': {
                        backgroundColor: colorItemBgSelected,
                    },
                },
            },
            // Danger - only Item has
            [`${componentCls}-item-danger`]: {
                color: colorDangerItemText,
                [`&${componentCls}-item:hover`]: {
                    [`&:not(${componentCls}-item-selected):not(${componentCls}-submenu-selected)`]: {
                        color: colorDangerItemTextHover,
                    },
                },
                [`&${componentCls}-item:active`]: {
                    background: colorDangerItemBgActive,
                },
            },
            [`${componentCls}-item a`]: {
                '&, &:hover': {
                    color: 'inherit',
                },
            },
            [`${componentCls}-item-selected`]: {
                color: colorItemTextSelected,
                // Danger
                [`&${componentCls}-item-danger`]: {
                    color: colorDangerItemTextSelected,
                },
                [`a, a:hover`]: {
                    color: 'inherit',
                },
            },
            [`& ${componentCls}-item-selected`]: {
                backgroundColor: colorItemBgSelected,
                // Danger
                [`&${componentCls}-item-danger`]: {
                    backgroundColor: colorDangerItemBgSelected,
                },
            },
            [`${componentCls}-item, ${componentCls}-submenu-title`]: {
                [`&:not(${componentCls}-item-disabled):focus-visible`]: Object.assign({}, accessibilityFocus(token)),
            },
            [`&${componentCls}-submenu > ${componentCls}`]: {
                backgroundColor: menuSubMenuBg,
            },
            [`&${componentCls}-popup > ${componentCls}`]: {
                backgroundColor: colorItemBg,
            },
            // ====================== Horizontal ======================
            [`&${componentCls}-horizontal`]: Object.assign(Object.assign({}, (themeSuffix === 'dark'
                ? {
                    borderBottom: 0,
                }
                : {})), { [`> ${componentCls}-item, > ${componentCls}-submenu`]: {
                    top: colorActiveBarBorderSize,
                    marginTop: -colorActiveBarBorderSize,
                    marginBottom: 0,
                    borderRadius: 0,
                    '&::after': {
                        position: 'absolute',
                        insetInline: menuItemPaddingInline,
                        bottom: 0,
                        borderBottom: `${colorActiveBarHeight}px solid transparent`,
                        transition: `border-color ${motionDurationSlow} ${motionEaseInOut}`,
                        content: '""',
                    },
                    [`&:hover, &-active, &-open`]: {
                        '&::after': {
                            borderBottomWidth: colorActiveBarHeight,
                            borderBottomColor: colorItemTextSelectedHorizontal,
                        },
                    },
                    [`&-selected`]: {
                        color: colorItemTextSelectedHorizontal,
                        backgroundColor: colorItemBgSelectedHorizontal,
                        '&::after': {
                            borderBottomWidth: colorActiveBarHeight,
                            borderBottomColor: colorItemTextSelectedHorizontal,
                        },
                    },
                } }),
            // ================== Inline & Vertical ===================
            //
            [`&${componentCls}-root`]: {
                [`&${componentCls}-inline, &${componentCls}-vertical`]: {
                    borderInlineEnd: `${colorActiveBarBorderSize}px ${lineType} ${colorSplit}`,
                },
            },
            // ======================== Inline ========================
            [`&${componentCls}-inline`]: {
                // Sub
                [`${componentCls}-sub${componentCls}-inline`]: {
                    background: colorSubItemBg,
                },
                // Item
                [`${componentCls}-item, ${componentCls}-submenu-title`]: colorActiveBarBorderSize && colorActiveBarWidth
                    ? {
                        width: `calc(100% + ${colorActiveBarBorderSize}px)`,
                    }
                    : {},
                [`${componentCls}-item`]: {
                    position: 'relative',
                    '&::after': {
                        position: 'absolute',
                        insetBlock: 0,
                        insetInlineEnd: 0,
                        borderInlineEnd: `${colorActiveBarWidth}px solid ${colorItemTextSelected}`,
                        transform: 'scaleY(0.0001)',
                        opacity: 0,
                        transition: [
                            `transform ${motionDurationMid} ${motionEaseOut}`,
                            `opacity ${motionDurationMid} ${motionEaseOut}`,
                        ].join(','),
                        content: '""',
                    },
                    // Danger
                    [`&${componentCls}-item-danger`]: {
                        '&::after': {
                            borderInlineEndColor: colorDangerItemTextSelected,
                        },
                    },
                },
                [`${componentCls}-selected, ${componentCls}-item-selected`]: {
                    '&::after': {
                        transform: 'scaleY(1)',
                        opacity: 1,
                        transition: [
                            `transform ${motionDurationMid} ${motionEaseInOut}`,
                            `opacity ${motionDurationMid} ${motionEaseInOut}`,
                        ].join(','),
                    },
                },
            },
        },
    };
};
export default getThemeStyle;
