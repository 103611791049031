var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ColorPickerPanel as RcColorPickerPanel } from '@rc-component/color-picker';
import React from 'react';
import Divider from '../divider';
import ColorClear from './components/ColorClear';
import ColorInput from './components/ColorInput';
import ColorPresets from './components/ColorPresets';
const ColorPickerPanel = (props) => {
    const { prefixCls, allowClear, presets, onChange, onClear, color } = props, injectProps = __rest(props, ["prefixCls", "allowClear", "presets", "onChange", "onClear", "color"]);
    const colorPickerPanelPrefixCls = `${prefixCls}-inner-panel`;
    const extraPanelRender = (panel) => (React.createElement("div", { className: colorPickerPanelPrefixCls },
        allowClear && (React.createElement(ColorClear, Object.assign({ prefixCls: prefixCls, value: color, onChange: (clearColor) => {
                onChange === null || onChange === void 0 ? void 0 : onChange(clearColor);
                onClear === null || onClear === void 0 ? void 0 : onClear(true);
            } }, injectProps))),
        panel,
        React.createElement(ColorInput, Object.assign({ value: color, onChange: onChange, prefixCls: prefixCls }, injectProps)),
        Array.isArray(presets) && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { className: `${colorPickerPanelPrefixCls}-divider` }),
            React.createElement(ColorPresets, { value: color, presets: presets, prefixCls: prefixCls, onChange: onChange })))));
    return (React.createElement(RcColorPickerPanel, { prefixCls: prefixCls, value: color === null || color === void 0 ? void 0 : color.toHsb(), onChange: onChange, panelRender: extraPanelRender }));
};
if (process.env.NODE_ENV !== 'production') {
    ColorPickerPanel.displayName = 'ColorPickerPanel';
}
export default ColorPickerPanel;
