import * as React from 'react';
import { ConfigContext } from '../../config-provider';
import defaultLocale from '../../locale/en_US';
import useLocale from '../../locale/useLocale';
import ConfirmDialog from '../ConfirmDialog';
const HookModal = ({ afterClose: hookAfterClose, config }, ref) => {
    var _a;
    const [open, setOpen] = React.useState(true);
    const [innerConfig, setInnerConfig] = React.useState(config);
    const { direction, getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('modal');
    const rootPrefixCls = getPrefixCls();
    const afterClose = () => {
        var _a;
        hookAfterClose();
        (_a = innerConfig.afterClose) === null || _a === void 0 ? void 0 : _a.call(innerConfig);
    };
    const close = (...args) => {
        setOpen(false);
        const triggerCancel = args.some((param) => param && param.triggerCancel);
        if (innerConfig.onCancel && triggerCancel) {
            innerConfig.onCancel(() => { }, ...args.slice(1));
        }
    };
    React.useImperativeHandle(ref, () => ({
        destroy: close,
        update: (newConfig) => {
            setInnerConfig((originConfig) => (Object.assign(Object.assign({}, originConfig), newConfig)));
        },
    }));
    const mergedOkCancel = (_a = innerConfig.okCancel) !== null && _a !== void 0 ? _a : innerConfig.type === 'confirm';
    const [contextLocale] = useLocale('Modal', defaultLocale.Modal);
    return (React.createElement(ConfirmDialog, Object.assign({ prefixCls: prefixCls, rootPrefixCls: rootPrefixCls }, innerConfig, { close: close, open: open, afterClose: afterClose, okText: innerConfig.okText || (mergedOkCancel ? contextLocale === null || contextLocale === void 0 ? void 0 : contextLocale.okText : contextLocale === null || contextLocale === void 0 ? void 0 : contextLocale.justOkText), direction: innerConfig.direction || direction, cancelText: innerConfig.cancelText || (contextLocale === null || contextLocale === void 0 ? void 0 : contextLocale.cancelText) })));
};
export default React.forwardRef(HookModal);
