import raf from 'rc-util/lib/raf';
import { composeRef } from 'rc-util/lib/ref';
import * as React from 'react';
import { useRef } from 'react';
import Tooltip from '../tooltip';
const SliderTooltip = React.forwardRef((props, ref) => {
    const { open } = props;
    const innerRef = useRef(null);
    const rafRef = useRef(null);
    function cancelKeepAlign() {
        raf.cancel(rafRef.current);
        rafRef.current = null;
    }
    function keepAlign() {
        rafRef.current = raf(() => {
            var _a;
            (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.forceAlign();
            rafRef.current = null;
        });
    }
    React.useEffect(() => {
        if (open) {
            keepAlign();
        }
        else {
            cancelKeepAlign();
        }
        return cancelKeepAlign;
    }, [open, props.title]);
    return React.createElement(Tooltip, Object.assign({ ref: composeRef(innerRef, ref) }, props));
});
export default SliderTooltip;
